import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-pagos-pendientes',
    templateUrl: './pagos-pendientes.component.html',
    styleUrls: ['./pagos-pendientes.component.css'],
    standalone: false
})
export class PagosPendientesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
