import { RouterModule, Routes } from "@angular/router"; 
import { NgModule } from "@angular/core";
import { AuthGuard } from "@src/app/services/Security/guard.service";
import { ChatComponent } from "./chat/Components/Chat.component";

const routes: Routes = [
 
  { path: "", component: ChatComponent }, 
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ChatSoporteRoutingModule {
 
  //Routing principal
  public static RoutingChat  = {
    path: "chat-soporte/chat",
    loadComponent: () =>
      import("./chat/Components/Chat.component").then(
        (m) => m.ChatComponent
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Chat`,
      icon: "fa-regular fa-messages",
 
    },
  };
}
