import { Component, Inject, OnInit, ViewContainerRef } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  ProcessType,
  SPlusDialogCloseResult,
  SPlusDialogSettings,
  SPlusFormsDialogsService,
} from "@saludplus/forms";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import {
  TegettFormControl,
  TypeField,
} from "@src/app/models/Tegett/tegettFormControl";
import { RoutingState } from "@src/app/services/Generals/routing.state.service";
import { DynamicFormComponent } from "../../components/dynamic-form.component";

import {
  BaseDynamicFormComponent,
  ControlType,
  DynamicForm,
  IconType,
} from "../../components/dynamic-form.settings";
@Component({
    selector: "app-banco",
    template: "",
    standalone: false
})
export class BancosPage implements BaseDynamicFormComponent {
  dynamicComp: DynamicFormComponent;
  formRecord = new UntypedFormGroup({
    // nombre
    nombreBanco: new TegettFormControl({
      formState: "",
      validatorOrOpts: [Validators.required],
      label: "Nombre",
      typeField: TypeField.Input,
      required: true,
      nameControl: "nombreBanco",
    }),

    // Dirección
    direccionBanco: new TegettFormControl({
      label: "Dirección",
      typeField: TypeField.Input,
      nameControl: "direccionBanco",
    }),

    // Teléfono
    telefonoBanco: new TegettFormControl({
      formState: "",
      label: "Teléfono",
      typeField: TypeField.Input,
      nameControl: "telefonoBanco",
    }),
  });

  form: DynamicForm = {
    title: "Bancos",
    recordName: "Banco",
    fieldViewModeName: "nombreBanco",
    endpointbase: ControllerApiList.Contabilidad.Bancos,
    formRecord: this.formRecord,
    header: {
      options: [
        {
          id: "Listado",
          icon: IconType.search,
          text: "Listado",
          link: "/contabilidad/bancos/listado",
        },
      ],

      moreOptions: [
        {
          id: "newRecord",
          showIn: ProcessType.view,
          text: "Crear nuevo banco",
          icon: "fa-duotone fa-plus",
          action: () => this.dynamicComp.newRecord(),
        },
        {
          id: "deleteRecord",
          showIn: ProcessType.edit,
          text: "Eliminar",
          icon: "fa-solid fa-trash-can",
          action: () => this.dynamicComp.DialogEliminar(),
        },
      ],
    },
    groups: [
      {
        title: "Información Básica",
        fields: [
          { control: "nombreBanco" },
          { control: "direccionBanco" },
          { control: "telefonoBanco", controlType: ControlType.numerictextbox },
        ],
      },
    ],
  };

  constructor() {
   
  }
  NewRecord(): void {
    throw new Error("Method not implemented.");
  }
  EditRecord(id: number): void {
    throw new Error("Method not implemented.");
  }
  DeleteRecord(id: number): void {
    throw new Error("Method not implemented.");
  }
  SaveRecord(form: UntypedFormGroup): void {
    throw new Error("Method not implemented.");
  }
  CancelRecord(): void {
    throw new Error("Method not implemented.");
  }
  GetRecord(id: number): void {
    throw new Error("Method not implemented.");
  }
  GetRecords(): void {
    throw new Error("Method not implemented.");
  }    



  
}
