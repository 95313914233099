import { Routes, RouterModule } from "@angular/router";
import { IncidenciasRoutingModule } from "./incidencias/incidencias.routing";
import { LogRoutingModule } from "./system-logs/log.routing";

const routes: Routes = [
  // Incidencias
  IncidenciasRoutingModule.RoutingIncidencias,
  LogRoutingModule.RoutingLogs
];
export const AuditoriasRoutes = RouterModule.forChild(routes);
