import { Routes, RouterModule } from "@angular/router";
import { ChatSoporteRoutingModule } from "../Chat.routing";


const routes: Routes = [
   ChatSoporteRoutingModule.RoutingChat

  
  
];
export const ChatSoporteRoutes = RouterModule.forChild(routes);
