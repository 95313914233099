import { Component } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import {
  ProcessType,
  SPlusDialogCloseResult,
  SPlusDialogSettings,
  SPlusFormsDialogsService,
} from "@saludplus/forms";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import {
  TegettFormControl,
  TypeField,
} from "@src/app/models/Tegett/tegettFormControl";
import { DynamicFormComponent } from "../../components/dynamic-form.component";
import {
  BaseDynamicFormComponent,
  ControlType,
  DynamicForm,
  IconType,
} from "../../components/dynamic-form.settings";
@Component({
    selector: "app-bodega",
    template: "",
    standalone: false
})
export class EquivalenciasPage implements BaseDynamicFormComponent {
  dynamicComp: DynamicFormComponent;
  formRecord = new UntypedFormGroup({
    // nombreEquivalenciaInventario
    nombreEquivalenciaInventario: new TegettFormControl({
      formState: "",
      validatorOrOpts: [Validators.required],
      asyncValidator: undefined,
      label: "Nombre Equivalencia",
      typeField: TypeField.Input,
      required: true,
      nameControl: "nombreEquivalenciaInventario",
    }),
    // unidadesEquivalenciaInventario
    unidadesEquivalenciaInventario: new TegettFormControl({
      formState: "",
      validatorOrOpts: [Validators.required],
      label: "Unidades Equivalencia",
      typeField: TypeField.Input,
      nameControl: "unidadesEquivalenciaInventario",
    }),
    // descripcionEquivalenciaInventario
    descripcionEquivalenciaInventario: new TegettFormControl({
      formState: "",
      label: "Descripción",
      typeField: TypeField.Input,
      funtionData: undefined,
      required: false,
      dataTextField: undefined,
      dataValueField: undefined,
      importNoRequired: false,
      parentDependency: undefined,
      nameControl: "descripcionEquivalenciaInventario",
    }),
  });

  form: DynamicForm = {
    title: "Equivalencias",
    recordName: "Equivalencia",
    fieldViewModeName: "nombreEquivalenciaInventario",
    formRecord: this.formRecord,
    endpointbase: ControllerApiList.Inventario.Equivalencias,
    header: {
      options: [
        {
          id: "Listado",
          icon: IconType.search,
          text: "Listado",
          link: "/inventario/equivalencias/listado",
        },
      ],

      moreOptions: [
        {
          id: "newRecord",
          showIn: ProcessType.view,
          text: "Crear nueva bodega",
          icon: "fa-duotone fa-plus",
          action: () => this.dynamicComp.newRecord(),
        },
        {
          id: "deleteRecord",
          showIn: ProcessType.edit,
          text: "Eliminar",
          icon: "fa-solid fa-trash-can",
          action: () => this.dynamicComp.DialogEliminar(),
        },
      ],
    },
    groups: [
      {
        title: "Información Básica",
        fields: [
          { control: "nombreEquivalenciaInventario" },
          { control: "unidadesEquivalenciaInventario", controlType: ControlType.numerictextbox },
          { control: "descripcionEquivalenciaInventario", controlType: ControlType.textarea },
        ],
      },
    ],
  };

  constructor() {}
  NewRecord(): void {
    throw new Error("Method not implemented.");
  }
  EditRecord(id: number): void {
    throw new Error("Method not implemented.");
  }
  DeleteRecord(id: number): void {
    throw new Error("Method not implemented.");
  }
  SaveRecord(form: UntypedFormGroup): void {
    throw new Error("Method not implemented.");
  }
  CancelRecord(): void {
    throw new Error("Method not implemented.");
  }
  GetRecord(id: number): void {
    throw new Error("Method not implemented.");
  }
  GetRecords(): void {
    throw new Error("Method not implemented.");
  }
}
