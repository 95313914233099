import { RouterModule, Routes } from "@angular/router"; 
import { NgModule } from "@angular/core";
import { AuthGuard } from "@src/app/services/Security/guard.service";
import { LogComponent } from "./Components/logs.component";

const routes: Routes = [
 
  { path: "", component: LogComponent }, 
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LogRoutingModule {
 
  //Routing principal
  public static RoutingLogs = {
    path: "auditoria/logs",
    loadComponent: () =>
      import("./Components/logs.component").then(
        (m) => m.LogComponent
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Sistema Logs`,
      icon: "fa-duotone fa-solid fa-square-info",
 
    },
  };
}
